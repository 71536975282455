@import '../commons/variables';
@import '../commons/placeholders';

.container {
    overflow: hidden;
    width: 100%;

    &.sectionMetstrat {
        @extend %section-metstrat;

        .top {
            @extend %border-metstrat;
        }

        .line {
            background-color: $color-red;
        }
    }

    &.sectionCampaigns {
        @extend %section-campaigns;

        .top {
            @extend %border-campaigns;
        }

        .line {
            background-color: $color-black;
        }
    }

    &.sectionContact {
        @extend %section-contact;
    }

    &.open {
        z-index: 1;

        .children {
            max-height: 66vh;
            overflow-y: auto;
        }

        .right {
            .square {
                opacity: 1;
            }
        }

        .tagline {
            opacity: 1;
        }

        &:nth-child(1),
        &:nth-child(2) {
            .children {
                max-height: 66vh;
            }
        }
    }

    &.showTagline {
        .tagline {
            opacity: 1;
        }
    }

    .children {
        overflow: hidden;
        height: auto;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        transition: max-height 0.33s ease-out;
    }

    .header {
        width: 100%;

        // height: 100%;
    }

    .images {
        display: none;
        visibility: hidden;
    }

    .blocks {
        padding: 20px;
    }

    .inner {
        @extend %reset-button;

        display: block;
        width: 100%;

        // height: 100%;
        margin: 0;

        &.sectionContact {
            @extend %section-contact-inverted;
        }
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        padding: 20px;
        margin: 0;
        font-family: $font-title;
        font-size: 32px;
        line-height: 1;
    }

    .tagline {
        padding: 20px 20px 50px;
        font-size: 28px;
        line-height: $line-height-normal;
        opacity: 0;
        text-align: left;
        transition: opacity 0.16s ease-out;
    }

    .separator {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 10px;

        .line {
            width: 100%;
            height: 2px;
        }
    }

    .right {
        padding: 20px;
        margin: 0;

        .square {
            width: 20px;
            height: 20px;
            opacity: 0;
            transition: opacity 0.16s ease-out;
        }

        &.sectionMetstrat {
            .square {
                @extend %section-metstrat-inverted;
            }
        }

        &.sectionCampaigns {
            .square {
                @extend %section-campaigns-inverted;
            }
        }

        &.sectionContact {
            .square {
                @extend %section-contact;
            }
        }
    }

    .desktopTagline {
        @extend %subtitle;

        display: none;
        visibility: hidden;
    }

    .fill {
        @extend %reset-button;

        display: none;
        visibility: hidden;
    }

    @media #{$mq-medium-screen} {
        position: relative;
        overflow: hidden;
        height: 100%;
        border-left: 1px solid $color-white;
        background-color: transparent !important;

        &:first-child {
            border-right: 1px solid $color-white;
            border-left: 0;
        }

        &.sectionMetstrat {
            .top {
                border-bottom: 0;
            }

            .desktopTagline {
                border-bottom: $border-style $color-red;
            }

            &.open {
                .children,
                .header {
                    background-color: $color-beige !important;
                }
            }
        }

        &.sectionCampaigns {
            .top {
                border-bottom: 0;
            }

            .desktopTagline {
                border-bottom: $border-style $color-black;
            }

            &.open {
                .children,
                .header {
                    background-color: $color-blue !important;
                }
            }
        }

        &.open {
            .children {
                max-height: 100%;
            }

            &:nth-child(1),
            &:nth-child(2) {
                .children {
                    max-height: 100%;
                }
            }

            .inner {
                border: 0;
            }

            .blocks {
                opacity: 1;
            }

            .fill {
                display: none;
                visibility: hidden;
            }
        }

        .title {
            padding: 30px;
            font-size: 56px;
            transition: color 0.16s ease-out;
        }

        .children {
            max-height: 0;
            padding: 0;
        }

        .blocks {
            padding: 0 30px 30px;
            padding-bottom: 140px;
            opacity: 0;
        }

        .top {
            justify-content: center;
        }

        .tagline,
        .separator {
            display: none;
            visibility: hidden;
        }

        .images {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            visibility: visible;

            .image {
                height: 50%;

                &:first-child {
                    border-bottom: 1px solid $color-white;
                }

                &:last-child {
                    border-top: 1px solid $color-white;
                }
            }
        }

        .right {
            display: none;
            visibility: hidden;
        }

        .desktopTagline {
            display: block;
            padding: 0 30px 50px;
            margin: 0 0 30px;
            text-align: center;
            visibility: visible;
        }

        .fill {
            display: block;
            width: 100%;
            height: 100%;
            visibility: visible;
        }
    }

    @media #{$mq-large-screen} {
        .title {
            padding: 30px;
        }

        .children {
            padding: 0 20px 20px;
        }
    }

    @media #{$mq-very-large-screen} {
        &:first-child {
            .desktopTagline {
                padding: 0 100px 80px;
            }
        }

        .desktopTagline {
            padding: 0 80px 80px;
        }
    }
}
