@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;

    .image {
        display: block;
        max-width: 100%;
        margin-bottom: 30px;
    }

    .name,
    .email,
    .body,
    .linkedin {
        @extend %text;

        margin-bottom: 30px;
    }

    .name {
        margin-bottom: 0;
    }

    @media #{$mq-small-screen} {
        .image {
            max-width: 100%;
        }

        .linkedin {
            margin-bottom: -5px;
        }
    }

    @media #{$mq-medium-screen} {
        .image {
            max-width: 200px;
        }

        .linkedin {
            margin-bottom: 30px;
        }
    }

    @media #{$mq-large-screen} {
        .image {
            max-width: 100%;
        }

        .linkedin {
            margin-bottom: -5px;
        }
    }
}
