@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;

    .subtitle {
        padding: 30px 0;
        font-size: $font-size-large;
    }

    .bullets {
        .bullet {
            padding-top: 1px;
            padding-bottom: 10px;
            font-size: $font-size-medium;

            &:last-child {
                padding-bottom: 1px;
            }
        }
    }
}
