@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    display: block;

    .image {
        display: block;
        max-width: 100%;
    }

    .empty {
        display: block;
        width: 100%;
        height: 100%;
    }

    .backgroundImage {
        position: absolute;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        background-position: center;
        background-repeat: no-repeat;

        &.cover {
            background-size: cover;
        }
    }

    .button {
        @extend %reset-button;

        position: absolute;
        z-index: 3;
        right: 10px;
        bottom: 10px;
        width: 19px;
        height: 19px;
        padding: 0;
        border: $border-style $color-black;
        transition: background-color 0.16s ease-out;

        .inner {
            width: 100%;
            height: 100%;
            border: 1px solid transparent;
        }

        &:hover {
            background-color: transparent;
        }
    }

    .credits {
        position: absolute;
        z-index: 2;
        right: 35px;
        bottom: 10px;
        padding: 4px 4px 3px;
        border: $border-style $color-black;
        margin-left: 6px;
        background-color: $color-background;
        font-size: 10px;
        line-height: 12px;
        opacity: 0;
        text-transform: uppercase;
        transition: opacity 0.16s ease-out;

        &.open {
            opacity: 1;
        }
    }

    @media #{$mq-medium-screen} {
        .credits {
            right: 33px;
            margin-left: 8px;
            font-size: 12px;
        }
    }
}
