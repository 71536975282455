@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;
    margin-bottom: 30px;

    .column {
        &:nth-child(1) {
            border-bottom: $border-style $color-red;
            margin-bottom: 30px;
        }
    }

    @media #{$mq-small-screen} {
        position: relative;
        display: flex;

        .column {
            width: 50%;

            &:nth-child(1) {
                padding-right: 30px;
                border-bottom: 0;
                margin-bottom: 0;
            }

            &:nth-child(2) {
                padding-left: 30px;
                border-left: $border-style $color-red;
                margin-bottom: 0;
            }
        }
    }

    @media #{$mq-medium-screen} {
        display: block;

        .column {
            width: 100%;

            &:nth-child(1) {
                padding-right: 0;
                border-bottom: 0;
            }

            &:nth-child(2) {
                padding-left: 0;
                border-left: 0;
            }
        }
    }

    @media #{$mq-large-screen} {
        position: relative;
        display: flex;

        .column {
            width: 50%;

            &:nth-child(1) {
                padding-right: 30px;
                border-bottom: 0;
            }

            &:nth-child(2) {
                padding-left: 30px;
                border-left: $border-style $color-red;
            }
        }
    }
}
