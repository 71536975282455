// stylelint-disable selector-max-compound-selectors
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %page;

    overflow: hidden;
    font-family: $font-text;

    .background {
        @extend %fullscreen;

        z-index: -1;
    }

    .block {
        margin-top: 30px;
        margin-bottom: 30px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .blockMetstrat {
        @extend %border-metstrat;
    }

    .blockCampaigns {
        @extend %border-campaigns;
    }

    .mobileFooterContainer {
        display: block;
        overflow: hidden;
        width: 100%;
    }

    .mobileFooter {
        width: 100%;
        height: 100%;

        img {
            min-width: 100%;
            min-height: 100%;
        }
    }

    @media #{$mq-medium-screen} {
        overflow: hidden;
        height: 100vh;

        .mobileFooter {
            display: none;
            visibility: hidden;
        }

        .background {
            background-image: url('../../img//Quayside-large.jpg');
            background-position: center;
            background-size: cover;
        }

        .inner {
            display: flex;
            width: 100%;
            height: 100%;

            > section {
                display: none;
                visibility: hidden;

                &:first-child,
                &:nth-child(2) {
                    display: block;
                    width: 50%;
                    height: 100%;
                    flex-grow: 1;
                    visibility: visible;
                }

                &:first-child {
                    // margin-right: 15px;
                }

                &:nth-child(2) {
                    // margin-left: 15px;
                }
            }
        }
    }
}
