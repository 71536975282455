/**
 * Colors
 */
$color-white: #fff;
$color-black: #000;
$color-red: #b12a31;
$color-beige: #fffdeb;
$color-blue: #adc3d8;
$color-orange: #fd6c40;
$color-background: $color-white;
$color-text: $color-black;
$color-border: $color-black;

/**
 * Fonts
 */
$font-title: lyon, 'Times New Roman', georgia, serif;
$font-text: lyon, 'Times New Roman', georgia, serif;
$font-size-normal: 16px;
$font-size-medium: 20px;
$font-size-medium-large: 24px;
$font-size-large: 30px;
$font-size-very-large: 42px;
$font-size-extra-large: 48px;

// Line height
$line-height-normal: 1.3;

/**
 * Screen size
 */

$small-screen: 500px;
$medium-screen: 790px;
$large-screen: 1000px;
$very-large-screen: 1260px;
$max-page-width: 1080px;
$border-style: 2px solid;
$button-transition: box-shadow 0.12s ease-out, transform 0.12s ease-out, color 0.12s ease-out;
$line-height-normal: 1.35;

/**
 * Media queries
 */

$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-very-large-screen: 'only screen and (min-width: #{$very-large-screen})';
$mq-high-screen: 'only screen and (min-width: #{$large-screen}) and (min-height: 800px)';
