@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: block;
    font-size: 24px;
    line-height: 1.35;

    small {
        font-size: 0.8em;
    }
}
