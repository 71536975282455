// stylelint-disable selector-max-compound-selectors
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    @extend %section-contact-inverted;

    display: none;
    visibility: hidden;

    @media #{$mq-medium-screen} {
        position: absolute;
        z-index: 0;
        bottom: 6vh;
        left: 50%;
        display: block;
        width: 360px;
        padding: 20px 20px 16px;
        border: 2px solid $color-white;
        margin-left: -180px;
        font-size: 24px;
        line-height: 1;
        text-align: center;
        visibility: visible;

        a {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.16s ease-out;

            &:hover {
                text-decoration-color: $color-white;
            }
        }

        p:first-child {
            margin-bottom: 10px;
        }
    }

    @media #{$mq-large-screen} {
        left: 50%;
        width: 380px;
        margin-left: -190px;
    }
}
