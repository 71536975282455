@mixin clearfix {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

@mixin placeholder {
    &::input-placeholder {
        @content;
    }

    &:placeholder {
        @content;
    }

    &::placeholder {
        @content;
    }

    &:input-placeholder {
        @content;
    }
}

@mixin keyframes($animationName) {
    @keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
