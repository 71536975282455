@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    margin-bottom: 30px;

    .paragraph {
        font-size: 24px;
        line-height: $line-height-normal;
    }

    .subtitle {
        @extend %subtitle;

        margin-bottom: 20px;
    }

    &.withBottomMargin {
        margin-bottom: 50px;
    }
}
