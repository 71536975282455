@font-face {
    font-family: Lyon;
    font-style: regular;
    font-weight: 400;
    src: url('../../fonts/lyon/LyonText-Regular-Web.woff2') format('woff2'),
        url('../../fonts/lyon/LyonText-Regular-Web.woff') format('woff');
}

@font-face {
    font-family: Lyon;
    font-style: italic;
    font-weight: 400;
    src: url('../../fonts/lyon/LyonText-RegularItalic-Web.woff2') format('woff2'),
        url('../../fonts/lyon/LyonText-RegularItalic-Web.woff') format('woff');
}
