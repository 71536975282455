@keyframes dash {
    to {
        stroke-dashoffset: 1000;
    }
}

@keyframes dash-small {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 100;
    }
}

@keyframes scan {
    0% {
        bottom: 100%;
    }

    75% {
        bottom: -200px;
    }

    100% {
        bottom: 100%;
    }
}

@keyframes revealHead {
    0% {
        clip-path: polygon(0 -100px, 100% -100px, 100% -100px, 0 -100px);
    }

    75% {
        clip-path: polygon(0 -100px, 100% -100px, 100% calc(100% + 100px), 0 calc(100% + 100px));
    }

    100% {
        clip-path: polygon(0 -100px, 100% -100px, 100% calc(100% + 100px), 0 calc(100% + 100px));
    }
}

@keyframes flash {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes rainbow {
    0% {
        filter: sepia(1) hue-rotate(0deg);
    }

    25% {
        filter: sepia(1) hue-rotate(90deg);
    }

    50% {
        filter: sepia(1) hue-rotate(180deg);
    }

    75% {
        filter: sepia(1) hue-rotate(270deg);
    }

    100% {
        filter: sepia(1) hue-rotate(360deg);
    }
}

@keyframes groove {
    from {
        transform: rotate(-2deg);
    }

    to {
        transform: rotate(2deg);
    }
}
