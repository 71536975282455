/**
 * Containers
 */

%fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%container-cols {
    display: table;
    width: 100%;
}

%container-col {
    display: table-cell;
}

/**
 * Controls
 */

%reset-button {
    position: relative;
    display: inline-block;
    padding: 0;
    border: 0;
    margin: 0;
    margin-bottom: 0 !important;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-family: $font-text;
    outline: none;
    text-decoration: none;
}

%reset-input {
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    cursor: pointer;
    font-family: $font-text;
}

/**
 * Media
 */

%hide-mobile {
    display: none;
    visibility: hidden;

    @media #{$mq-medium-screen} {
        display: block;
        visibility: visible;
    }
}

%show-mobile {
    display: block;
    visibility: visible;

    @media #{$mq-medium-screen} {
        display: none;
        visibility: hidden;
    }
}

/**
 * Pages
 */

%page {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @media #{$mq-medium-screen} {
        max-width: 1600px;

        // padding: 30px;
    }
}

/**
 * Text
 */

%title {
    font-family: $font-title;
    font-size: $font-size-extra-large;
    line-height: 1;
}

%subtitle {
    font-family: $font-title;
    font-size: 36px;
    line-height: $line-height-normal;
}

%text {
    font-family: $font-text;
    font-size: 20px;
    line-height: $line-height-normal;
}

%section-metstrat {
    background-color: $color-beige;
    color: $color-red;
}

%section-metstrat-inverted {
    background-color: $color-red;
    color: $color-beige;
}

%section-campaigns {
    background-color: $color-blue;
    color: $color-black;
}

%section-campaigns-inverted {
    background-color: $color-black;
    color: $color-blue;
}

%section-contact {
    background-color: $color-white;
    color: $color-orange;
}

%section-contact-inverted {
    background-color: $color-orange;
    color: $color-white;
}

%border-metstrat {
    border-bottom: 2px solid $color-red;
}

%border-campaigns {
    border-bottom: 2px solid $color-black;
}

%border-contact {
    border-bottom: 2px solid $color-orange;
}

%html-content {
    p {
        // font-weight: bold;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }

    small {
        font-size: 0.8em;
    }
}
